<template>
  <div class="section-gallery px-3 px-sm-4 py-5" id="gallery">
    <FlowerIl />
    <h1 class="h1-couple text-center mb-5">Gallery</h1>
    <template v-if="photosMore">
      <div
        class="row"
        v-for="(photo, index) in photosChunked"
        :key="`photo-${index}`"
      >
        <div v-for="item in photo" :key="item.id" class="col-6 mb-3">
          <zoom-image :src="item.file" />
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="item in photos" :key="item.id" class="row">
        <div class="col-12 mb-3">
          <zoom-image :src="item.file" />
        </div>
      </div>
    </template>
    <!-- <div>
      <iframe :src="videos.url" frameborder="0" allowfullscreen></iframe>
    </div> -->
    <!-- Our Stories -->
    <div class="section-show px-3 px-sm-4 py-5">
      <h1
        data-aos="fade-down"
        data-aos-duration="1500"
        class="h1-couple text-center mb-3"
      >
        Our Stories
      </h1>
      <p data-aos="fade-up" data-aos-duration="1500" class="text-center">
        “{{ dalil.isi }}” <br /><br />
        <span class="fw-bold">
          {{ dalil.sumber }}
        </span>
      </p>
      <Slider
        width="320px"
        height="350px"
        :autoplay="false"
        animation="normal"
        v-model="sliderValue"
        :duration="5000"
        :speed="1000"
        :class="sliderStory"
      >
        <SliderItem
          v-for="(i, index) in stories"
          :key="index"
          @click="changeIndex(1)"
        >
          <div class="section-stories mx-5 mb-3 mt-1">
            <div class="section-stories-text py-4 px-3">{{ i.text }}</div>
            <h3 class="mt-2 px-4">{{ i.year }}</h3>
          </div>
        </SliderItem>
      </Slider>

      <div
        :class="`section-stories mx-4 mb-3 mt-4 ${notSliderStory}`"
        v-for="(s, index) in stories"
        :key="index"
      >
        <div class="section-stories-text py-5 px-3">"{{ s.text }}"</div>
        <h3 class="my-3 text-center">{{ s.year }}</h3>
      </div>
    </div>
    <!-- Our Stories end -->
    <!-- Gift -->
    <GiftIc class="mt-5" />
    <h1 class="h1-couple text-center my-3">Gift</h1>
    <p class="text-center">
      Bagi kamu yang ingin memberikan hadiah untuk pernikahan kami, silahkan
      transfer ke rekening berikut :
    </p>
    <div class="account-wrapper px-4 px-sm-5 py-5 mt-3 mb-5">
      <h2 class="text-center">{{ couple.female.account.type }}</h2>
      <p class="text-center my-4">
       {{ couple.female.account.id }} <br />
        a/n <strong>{{ couple.female.account.name }}</strong>
      </p>
      <button
        class="btn"
        v-clipboard:copy="couple.female.account.id"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        Copy Alamat
        <CopyIc class="ms-1" height="1rem" />
      </button>
    </div>
    <div class="account-wrapper px-4 px-sm-5 py-5 mt-3 mb-5">
      <h2 class="text-center">{{ couple.male.account.type }}</h2>
      <p class="text-center my-4">
        No. Rekening {{ couple.male.account.id }} <br />
        a/n <strong>{{ couple.male.account.name }}</strong>
      </p>
      <button
        class="btn"
        v-clipboard:copy="couple.male.account.id"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        Copy Nomor Rekening
        <CopyIc class="ms-1" height="1rem" />
      </button>
    </div>
    <!-- Gift end -->
    <!-- Konfirmasi Kehadiran -->
    <div class="section-show px-3 px-sm-4 px-md-5">
      <h6 class="text-center mb-3">
        Tekan tombol di bawah ini! Dan pastikan kehadiran kamu untuk :
      </h6>
      <h3 class="text-handwrite text-center mb-3">
        {{ couple.female.nickname }} & {{ couple.male.nickname }}
      </h3>
      <button
        class="btn btn-hy-secondary mb-2"
        data-bs-target="#formAttend-2"
        data-bs-toggle="modal"
      >
        Konfirmasi ke Mempelai Wanita
      </button>
      <button
        class="btn btn-hy-secondary"
        data-bs-target="#formAttend-1"
        data-bs-toggle="modal"
      >
        Konfirmasi ke Mempelai Pria
      </button>
    </div>
    <confirm-attendance :phone="phone" />
    <!-- Konfirmasi Kehadiran end -->
  </div>
</template>

<script>
const CopyIc = () => import("@/assets/icons/icon-copy.svg");
const GiftIc = () => import("@/assets/icons/icon-gift-box.svg");
const FlowerIl = () => import("@/assets/illustrations/flower-section.svg");

import Vue from "vue";
import { Slider, SliderItem } from "vue-easy-slider";

const ZoomImage = () => import("@/components/ZoomImage.vue");
const ConfirmAttendance = () => import("@/components/ConfirmAttendance.vue");

export default {
  name: "GallerySection",
  props: [
    "couple",
    "phone",
    "photos",
    "stories",
    "dalil",
    "storySlide",
    "videos",
  ],
  data() {
    return {
      sliderValue: 0,
    };
  },
  components: {
    CopyIc,
    GiftIc,
    ZoomImage,
    ConfirmAttendance,
    Slider,
    SliderItem,
    FlowerIl,
  },
  computed: {
    photosMore() {
      return this.photos.length > 1;
    },
    photosChunked() {
      const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
          arr.slice(i * size, i * size + size)
        );
      return chunk(this.photos, 2);
    },
    sliderStory() {
      return this.storySlide ? "d-block" : "d-none";
    },
    notSliderStory() {
      return !this.storySlide ? "d-block" : "d-none";
    },
  },
  methods: {
    onCopy: function () {
      Vue.$toast.success("Berhasil di Copy!");
    },
    onError: function (e) {
      Vue.$toast.error("Gagal di Copy!");
      console.log(e);
    },
    changeIndex(index) {
      this.sliderValue = index;
    },
  },
};
</script>

<style>
</style>